import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 897.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,897.000000) scale(0.100000,-0.100000)">
		<path d="M6624 8780 c-113 -16 -240 -62 -639 -232 -401 -171 -497 -225 -644
-364 -116 -109 -210 -242 -327 -459 -349 -651 -335 -1451 35 -2060 181 -298
425 -517 759 -684 443 -221 855 -276 2036 -269 511 2 582 1 679 -15 319 -53
524 -149 655 -305 88 -107 152 -247 182 -405 28 -142 -32 -450 -115 -593 -31
-53 -85 -100 -125 -109 -32 -7 -34 -10 -44 -56 -7 -31 -44 -56 -132 -88 -32
-12 -90 -38 -128 -57 -167 -85 -244 -99 -457 -82 -179 15 -904 18 -3739 18
-1207 -1 -2302 -5 -2434 -10 -241 -10 -813 3 -828 18 -19 19 -41 535 -42 967
-1 444 6 568 36 665 23 74 -81 68 1101 61 1431 -8 2244 -8 2289 2 71 15 61 63
-40 214 -32 48 -67 110 -77 136 -10 27 -30 56 -44 65 -50 34 -107 99 -140 162
-101 194 -217 356 -326 458 -124 115 -199 138 -435 128 -74 -4 -904 -9 -1845
-12 -940 -3 -1711 -7 -1712 -8 -2 -2 5 -23 14 -47 15 -38 17 -72 13 -244 -11
-565 -1 -3728 12 -3739 3 -3 1296 -17 3568 -37 1305 -12 4296 -5 4675 10 220
9 284 19 505 75 30 8 91 17 135 21 274 22 502 151 806 454 195 196 452 509
509 621 92 181 153 537 154 900 1 279 -22 417 -121 710 -96 287 -218 473 -404
620 -65 52 -95 83 -113 119 -20 38 -41 59 -96 95 -39 26 -103 77 -142 112
-115 105 -313 190 -640 274 -286 72 -394 84 -788 85 -209 0 -430 -6 -560 -15
-264 -18 -745 -19 -890 -2 -147 18 -365 69 -430 101 -52 25 -55 28 -66 81 -8
34 -19 59 -31 65 -56 31 -184 206 -235 324 -72 164 -97 314 -78 457 40 302
251 525 604 638 126 40 404 66 722 66 94 0 197 5 229 11 150 28 168 52 440
584 76 149 162 316 192 373 61 118 66 153 26 179 -24 16 -72 17 -582 18 -306
1 -626 4 -711 8 -85 3 -183 2 -216 -3z"/>
<path d="M8378 8253 c-143 -291 -263 -531 -265 -535 -2 -5 178 -8 400 -8 l405
0 261 526 c143 289 261 530 261 535 0 5 -164 9 -400 9 l-401 0 -261 -527z"/>
<path d="M9888 8708 c-186 -370 -488 -983 -488 -990 0 -4 181 -7 401 -6 l402
3 57 115 c32 63 145 288 250 500 105 212 195 390 200 395 4 6 11 20 14 33 l7
22 -403 0 -404 0 -36 -72z"/>
<path d="M715 8710 c-519 -5 -563 -6 -597 -23 -41 -21 -48 -34 -47 -87 1 -19
2 -253 3 -520 2 -444 8 -576 30 -597 5 -5 2599 16 3231 26 496 7 480 5 544 73
17 18 61 78 99 133 38 55 136 191 218 302 249 338 405 585 428 676 l7 28
-1678 -3 c-923 -2 -1930 -5 -2238 -8z"/>
<path d="M5129 977 c-52 -18 -89 -51 -105 -94 -17 -50 -17 -65 1 -111 20 -49
61 -73 188 -106 112 -30 135 -42 144 -74 19 -77 -92 -118 -192 -71 -38 18 -50
31 -73 80 -23 51 -31 59 -55 59 l-28 0 3 -102 c3 -98 4 -103 25 -106 17 -3 26
3 33 22 l9 25 58 -30 c102 -52 210 -40 279 32 26 27 29 37 29 99 0 62 -3 70
-28 94 -42 39 -77 54 -183 81 -99 24 -134 44 -134 77 0 53 58 85 135 74 51 -8
110 -60 123 -109 6 -25 14 -33 35 -35 l27 -3 0 95 c0 83 -2 96 -17 96 -9 0
-25 -8 -35 -17 -17 -15 -21 -15 -57 5 -47 27 -134 36 -182 19z"/>
<path d="M7742 964 c-58 -40 5 -118 69 -84 39 21 11 100 -36 100 -6 0 -21 -7
-33 -16z"/>
<path d="M8840 973 c-8 -3 -18 -11 -22 -17 -12 -17 -9 -61 4 -74 20 -20 65
-14 82 10 21 29 20 40 -4 66 -21 22 -33 25 -60 15z"/>
<path d="M670 946 c0 -20 6 -24 40 -30 l40 -6 0 -200 0 -199 -37 -3 c-33 -3
-38 -7 -41 -30 l-3 -28 255 0 256 0 0 110 0 110 -25 0 c-18 0 -26 -6 -29 -22
-24 -127 -33 -135 -168 -141 l-98 -5 0 100 0 101 43 -7 c43 -7 67 -27 67 -57
0 -33 12 -49 36 -49 l24 0 0 130 0 130 -24 0 c-20 0 -25 -7 -35 -44 -11 -46
-26 -56 -83 -56 l-28 0 0 85 0 85 85 0 c92 0 147 -16 163 -47 5 -10 13 -37 17
-60 6 -37 10 -43 31 -43 24 0 24 1 24 100 l0 100 -255 0 -255 0 0 -24z"/>
<path d="M5992 948 c2 -17 11 -24 30 -26 l28 -3 2 -232 3 -232 27 -3 c19 -2
28 2 33 17 8 27 19 26 58 -1 20 -14 50 -23 86 -26 70 -5 115 22 149 88 20 38
23 56 19 116 -4 83 -26 127 -77 161 -51 35 -141 25 -179 -20 -24 -29 -31 -8
-31 89 l0 94 -76 0 c-72 0 -75 -1 -72 -22z m282 -192 c28 -12 35 -22 46 -64
14 -61 5 -133 -22 -167 -15 -19 -29 -25 -56 -25 -50 1 -71 19 -88 76 -24 80 2
167 56 187 23 8 28 8 64 -7z"/>
<path d="M8526 922 c-7 -45 -38 -90 -72 -102 -34 -13 -32 -48 4 -52 l27 -3 3
-136 c2 -129 4 -138 27 -163 18 -20 34 -26 65 -26 75 0 110 44 110 137 0 40
-2 44 -22 41 -19 -3 -23 -10 -26 -48 -3 -47 -23 -73 -48 -63 -11 4 -14 33 -14
134 l0 129 51 0 c47 0 50 2 47 23 -3 19 -9 22 -51 22 l-47 0 0 73 c0 69 -1 72
-24 72 -20 0 -24 -6 -30 -38z"/>
<path d="M1805 809 c-45 -21 -91 -84 -101 -136 -24 -128 63 -233 194 -233 65
0 104 19 137 67 34 52 33 73 -5 73 -19 0 -30 -5 -30 -14 0 -8 -11 -27 -23 -42
-20 -23 -31 -27 -71 -27 -29 1 -54 8 -66 18 -18 17 -43 93 -34 107 3 4 63 8
134 8 l130 0 -6 33 c-10 58 -24 92 -46 118 -45 50 -141 63 -213 28z m125 -39
c16 -8 40 -58 40 -81 0 -5 -36 -9 -80 -9 -70 0 -80 2 -80 18 0 18 26 61 44 74
15 11 54 10 76 -2z"/>
<path d="M2355 823 c-11 -3 -32 -16 -47 -30 l-26 -25 -4 23 c-3 23 -7 24 -78
24 -70 0 -75 -1 -78 -22 -3 -19 1 -23 23 -23 34 0 45 -31 45 -129 0 -119 -7
-141 -41 -141 -24 0 -29 -4 -29 -25 l0 -25 120 0 120 0 0 25 c0 23 -4 25 -40
25 l-40 0 0 99 c0 55 5 112 10 126 13 34 49 64 76 65 31 0 37 -11 13 -28 -24
-16 -23 -60 2 -75 25 -16 68 0 80 30 15 39 3 71 -34 93 -33 20 -37 21 -72 13z"/>
<path d="M2615 816 c-78 -36 -107 -122 -65 -190 l22 -36 -31 -29 c-38 -35 -39
-64 -5 -98 25 -25 25 -27 9 -39 -10 -7 -21 -15 -26 -16 -4 -2 -11 -15 -14 -30
-27 -107 314 -134 395 -30 43 54 13 132 -58 152 -20 5 -80 10 -132 10 -103 0
-125 7 -116 36 5 17 14 19 80 16 96 -5 137 9 171 58 22 32 26 47 22 77 -13 88
-13 87 15 97 35 12 46 2 25 -22 -22 -24 -21 -35 1 -55 24 -22 45 -21 65 1 24
26 21 59 -8 87 -28 29 -63 32 -107 9 -26 -13 -36 -14 -51 -4 -30 18 -156 22
-192 6z m132 -52 c23 -23 25 -31 21 -81 -5 -62 -23 -83 -70 -83 -46 0 -68 31
-68 95 0 42 5 60 20 75 27 27 66 25 97 -6z m42 -345 c36 -13 42 -46 14 -72
-40 -35 -187 -33 -223 3 -24 24 -16 56 19 69 36 13 151 13 190 0z"/>
<path d="M3243 818 c-18 -5 -37 -17 -43 -28 -14 -26 -28 -25 -32 3 -3 21 -8
22 -78 22 -70 0 -75 -1 -78 -22 -3 -18 2 -23 20 -23 46 0 49 -9 46 -141 l-3
-124 -32 -3 c-28 -3 -33 -7 -33 -28 l0 -25 123 3 c114 3 122 4 122 23 0 16 -8
21 -37 23 -47 4 -53 21 -45 137 5 92 28 140 72 151 37 9 50 -5 26 -27 -25 -24
-26 -28 -5 -57 31 -45 98 -16 98 42 0 57 -56 91 -121 74z"/>
<path d="M3532 819 c-77 -23 -122 -93 -122 -188 0 -82 26 -132 85 -167 40 -23
54 -26 109 -22 78 5 129 35 151 91 19 48 20 47 -15 47 -24 0 -32 -6 -44 -34
-12 -28 -23 -37 -55 -46 -74 -20 -131 27 -131 106 0 24 0 24 130 24 l130 0 0
33 c0 42 -37 115 -71 139 -30 23 -120 32 -167 17z m111 -52 c10 -6 20 -29 24
-50 l6 -37 -77 0 c-73 0 -76 1 -76 24 0 38 37 76 74 76 18 0 40 -6 49 -13z"/>
<path d="M3942 817 c-82 -26 -136 -143 -111 -242 14 -57 73 -119 120 -129 111
-22 197 13 223 91 l15 43 -29 0 c-23 0 -32 -7 -46 -35 -20 -42 -65 -59 -120
-44 -38 11 -64 53 -64 104 l0 25 131 0 131 0 -7 48 c-8 53 -18 76 -49 108 -40
43 -119 55 -194 31z m115 -45 c13 -8 33 -55 33 -78 0 -11 -17 -14 -80 -14
l-80 0 6 28 c14 57 78 92 121 64z"/>
<path d="M4485 816 c-16 -8 -40 -23 -52 -35 -29 -28 -33 -27 -33 9 l0 30 -80
0 c-79 0 -80 0 -80 -25 0 -19 5 -25 23 -25 41 0 47 -17 47 -131 0 -59 -3 -114
-6 -123 -4 -10 -18 -16 -35 -16 -24 0 -29 -4 -29 -25 l0 -25 110 0 c110 0 110
0 110 24 0 19 -6 25 -27 28 l-28 3 0 108 c0 107 0 109 29 133 21 19 38 24 64
22 49 -4 62 -32 62 -138 0 -110 -6 -130 -36 -130 -19 0 -24 -5 -24 -25 l0 -25
110 0 c110 0 110 0 110 24 0 21 -6 25 -32 28 l-33 3 -5 125 c-6 140 -15 165
-65 186 -42 17 -61 17 -100 0z"/>
<path d="M6568 820 c-70 -21 -99 -102 -58 -159 19 -28 65 -49 150 -71 56 -15
70 -26 70 -56 0 -32 -33 -47 -87 -41 -49 5 -75 24 -93 68 -10 22 -19 29 -40
29 -28 0 -28 0 -28 -70 0 -66 1 -70 23 -70 12 0 28 6 34 14 10 11 17 11 48 -5
64 -33 175 -20 211 24 19 23 26 83 13 115 -16 37 -42 52 -135 77 -96 26 -121
50 -86 85 40 40 137 8 156 -52 3 -11 15 -18 30 -18 23 0 24 3 24 65 0 56 -3
65 -18 65 -10 0 -23 -5 -29 -11 -9 -9 -22 -8 -53 5 -43 17 -88 19 -132 6z"/>
<path d="M7003 819 c-49 -14 -109 -77 -123 -128 -36 -133 50 -251 182 -251 85
0 158 61 158 133 0 22 -4 27 -24 27 -19 0 -27 -8 -36 -35 -22 -68 -115 -84
-160 -28 -16 20 -20 40 -20 95 0 74 17 120 49 138 26 13 77 13 103 -1 l20 -11
-21 -19 c-26 -25 -27 -43 -1 -69 26 -26 46 -25 75 5 31 30 31 53 3 95 -36 52
-122 73 -205 49z"/>
<path d="M7502 819 c-13 -5 -32 -19 -42 -32 l-18 -22 -11 28 c-11 27 -12 28
-83 25 -66 -3 -73 -5 -76 -25 -3 -18 2 -22 30 -25 l33 -3 3 -132 3 -133 -36 0
c-32 0 -36 -3 -33 -22 3 -22 7 -23 126 -26 l122 -3 0 26 c0 22 -4 25 -39 25
-45 0 -51 11 -51 97 0 121 36 193 96 193 28 0 31 -15 8 -28 -20 -11 -22 -61
-3 -73 42 -28 106 24 93 75 -11 44 -76 73 -122 55z"/>
<path d="M8170 820 c-19 -5 -45 -20 -57 -34 l-22 -26 -3 28 c-3 27 -4 27 -78
27 -68 0 -75 -2 -75 -20 0 -12 8 -21 20 -23 44 -9 45 -16 45 -221 0 -208 -3
-221 -47 -221 -18 0 -23 -6 -23 -25 l0 -25 120 0 120 0 0 24 c0 21 -5 25 -37
28 l-38 3 -3 84 -3 84 36 -32 c30 -26 44 -31 88 -31 102 0 156 65 157 190 0
139 -83 219 -200 190z m67 -74 c27 -23 28 -28 28 -111 0 -83 -1 -88 -28 -111
-47 -40 -104 -29 -132 26 -20 38 -19 133 1 171 26 52 86 63 131 25z"/>
<path d="M9160 819 c-45 -13 -106 -73 -121 -117 -19 -58 -7 -144 25 -187 66
-87 207 -101 309 -32 74 51 91 190 30 264 -16 21 -44 46 -62 56 -37 22 -132
31 -181 16z m141 -67 c26 -23 31 -36 36 -90 4 -45 1 -74 -11 -107 -20 -52 -39
-65 -96 -65 -76 0 -109 61 -97 178 5 47 11 62 37 85 41 37 89 37 131 -1z"/>
<path d="M9735 816 c-16 -7 -41 -23 -55 -36 l-25 -22 -5 29 c-5 28 -6 28 -80
28 -70 0 -75 -1 -78 -22 -3 -18 1 -23 20 -23 42 0 48 -17 48 -135 0 -118 -6
-135 -47 -135 -18 0 -23 -6 -23 -25 l0 -26 108 3 c101 3 107 4 110 25 3 17 -2
22 -25 25 l-28 3 0 108 c0 107 1 109 28 133 31 26 87 33 105 11 15 -17 26
-155 18 -211 -6 -40 -10 -46 -32 -46 -19 0 -24 -5 -24 -25 l0 -25 105 0 105 0
0 25 c0 21 -5 25 -29 25 l-29 0 -4 123 c-5 140 -18 178 -69 196 -40 14 -57 13
-94 -3z"/>
<path d="M1257 814 c-15 -15 -7 -44 12 -44 28 0 38 -18 82 -146 63 -187 55
-174 104 -174 45 0 58 9 70 50 4 14 25 76 48 139 36 100 45 116 69 125 21 8
28 17 26 31 -3 18 -10 20 -88 20 -81 0 -85 -1 -88 -22 -3 -18 2 -23 18 -23 40
0 37 -36 -12 -168 -11 -30 -23 -50 -27 -45 -13 14 -61 160 -61 183 0 14 8 24
21 27 14 4 19 12 17 27 -3 19 -10 21 -93 24 -50 1 -94 0 -98 -4z"/>
<path d="M5507 813 c-17 -16 -5 -41 22 -46 36 -7 41 -25 41 -150 0 -138 23
-177 105 -177 42 0 69 12 108 48 15 14 28 23 28 19 1 -4 2 -17 3 -29 1 -22 6
-23 79 -26 77 -3 77 -3 77 23 0 20 -5 25 -24 25 -14 0 -28 6 -33 13 -4 6 -10
77 -13 157 l-5 145 -80 0 c-73 0 -80 -2 -80 -20 0 -15 8 -21 35 -25 l35 -5 3
-94 c4 -133 -16 -171 -89 -173 -56 -2 -63 16 -61 168 2 102 -1 140 -10 146
-16 9 -132 11 -141 1z"/>
<path d="M7667 814 c-4 -4 -7 -16 -7 -26 0 -13 8 -18 28 -18 15 0 33 -5 40
-12 13 -13 17 -225 4 -245 -4 -6 -21 -13 -37 -15 -22 -2 -30 -8 -30 -23 0 -19
8 -20 118 -23 l117 -3 0 25 c0 21 -5 25 -32 28 l-33 3 -5 155 -5 155 -75 3
c-42 1 -79 0 -83 -4z"/>
<path d="M8757 813 c-16 -16 -6 -43 16 -43 45 0 48 -9 45 -141 l-3 -124 -32
-3 c-27 -3 -33 -7 -33 -28 l0 -24 115 0 115 0 0 25 c0 21 -5 25 -29 25 -38 0
-41 13 -41 183 l0 137 -73 0 c-41 0 -77 -3 -80 -7z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
